import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { useNavigate } from "react-router-dom";

//For Images Import
import goldLogo from "../../assets/goldLogo.png";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";

//For Css Import
import "./Header.css";

const Header = () => {
  const navigate = useNavigate();
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    setIsSmallScreen(false);
    setNavVisibility(false);
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    setIsSmallScreen(mediaQuery.matches);
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  const handleloginLogout = () => {
    if (localStorage.getItem("userDataa") === "true") {
      localStorage.clear();
      setNavVisibility(false);
      navigate("/");
    } else {
      setNavVisibility(false);
      navigate("/login");
    }
  };

  const handleClickOutside = (event) => {
    // console.log(event)
    // console.log(navRef.current)
    // console.log(navRef.current.contains(event.target))
    if (navRef.current && !navRef.current.contains(event.target)) {
      setNavVisibility(false);
    }
  };
  const handleScroll = () => {
    setNavVisibility(false);
  };

  return (
    <header className="Header">
      <img
        src={goldLogo}
        className="Logo"
        alt="pak gold info logo"
        title="pak gold info"
      />
      <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit
      >
        <nav ref={navRef} className="Nav">
          <a href="/">Live Rates</a>
          <a href="/goldHistory">Insights</a>
          <a href="/calculateMetals">Gold Calculator</a>
          <a href="/calculatesilver">Silver Calculator</a>
          <a href="/trading">Trading Guide</a>
          <button onClick={handleloginLogout}>
            {localStorage.getItem("userDataa") === "true" ? "Logout" : "Login"}
          </button>
        </nav>
      </CSSTransition>
      <button onClick={toggleNav} className="Burger">
        <DensityMediumIcon style={{ color: "white" }} />
      </button>
    </header>
  );
  // const navigate = useNavigate();
  // const [isNavVisible, setNavVisibility] = useState(false);
  // const [isSmallScreen, setIsSmallScreen] = useState(false);

  // useEffect(() => {
  //   setIsSmallScreen(false);
  //   setNavVisibility(false);
  //   const mediaQuery = window.matchMedia("(max-width: 700px)");
  //   mediaQuery.addListener(handleMediaQueryChange);
  //   handleMediaQueryChange(mediaQuery);

  //   return () => {
  //     mediaQuery.removeListener(handleMediaQueryChange);
  //   };
  // }, []);

  // const handleMediaQueryChange = (mediaQuery) => {
  //   if (mediaQuery.matches) {
  //     setIsSmallScreen(true);
  //   } else {
  //     setIsSmallScreen(false);
  //   }
  // };

  // const toggleNav = () => {
  //   setNavVisibility(!isNavVisible);
  // };

  // const handleloginLogout = () => {
  //   if (localStorage.getItem("userDataa") === "true") {
  //     localStorage.clear();
  //     navigate("/");
  //   } else {
  //     navigate("/login");
  //   }
  // };
  // return (
  //   <header className="Header">
  //     <img src={goldLogo} className="Logo" alt="logo" />
  //     <CSSTransition
  //       in={!isSmallScreen || isNavVisible}
  //       timeout={350}
  //       classNames="NavAnimation"
  //       unmountOnExit
  //     >
  //       <nav className="Nav">
  //         <a href="/">Live Rates</a>
  //         <a href="/goldHistory">Insights</a>
  //         <a href="/calculateMetals">Gold Calculator</a>
  //         <a href="/calculatesilver">Silver Calculator</a>
  //         <a href="/trading">Trading Guide</a>
  //         <button onClick={handleloginLogout}>
  //           {localStorage.getItem("userDataa") === "true" ? "Logout" : "Login"}
  //         </button>
  //       </nav>
  //     </CSSTransition>
  //     <button onClick={toggleNav} className="Burger">
  //       <DensityMediumIcon style={{ color: "white" }} />
  //     </button>
  //   </header>
  // );
};

export default Header;

import React, { useEffect, useState } from "react";
import "./VisitorsCounter.css";
import { getVisitorsCount } from "../../actions/visitorsCount";

const VisitorsCounter = () => {
  const [totalVisits, setTotalVisits] = useState(0);
  const [todayVisit, setTodayVisit] = useState(0);
  useEffect(() => {
    getVisitors();
  }, []);
  const getVisitors = async () => {
    const data = await getVisitorsCount();
    setTotalVisits(data.existingVisitor.totalVisits);
    setTodayVisit(data.existingVisitor.dailyVisitors);
  };
  return (
    <div className="containerVisitor">
      <div className="rowVisitor">
        <div className="col-md-3 col-sm-6">
          <div className="counter">
            <div className="counter-icon">
              <span>
                <i className="fa fa-briefcase"></i>
              </span>
            </div>
            <span className="counter-value">Total Visit : {totalVisits}</span>
            <span className="counter-value">Today Visit : {todayVisit}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitorsCounter;

import React, { useState, useEffect } from "react";

//for material ui DropDown
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TableComponentId from "../tableComponent/tableComponentId";

//for importing css files
import "./RatesContainer.css";

let page = "Metal";
let decimalPoints = 2;

const MetalContainer = ({ data, update }) => {
  console.log(data);
  // console.log(data.length > 0);
  const [baseCurrency, setBaseCurrency] = useState(1);
  const [currenciesForMap, setCurrenciesForMap] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    // Update the date and time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  // Format the date to "DD-MM-YYYY hh:mm:ss A"
  // Format the date and time
  const formattedDateTime =
    currentTime.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }) +
    " " +
    currentTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

  useEffect(() => {
    // Extracting symbols and values into a single array
    const symbolValuePairs = data.map((item, index) => ({
      symbol: item.symbol,
      value: item.normal,
    }));
    setCurrenciesForMap(symbolValuePairs);
  }, [data]);

  const columns = [
    { field: "displayName", label: "Name" },
    { field: "data.bid", label: "BID" },
    { field: "data.ask", label: "ASK" },
    {
      field: "data",
      label: "Low/High",
      format: "lowHigh",
      render: (item) => `${item.data.low} </br> ${item.data.high}`,
    },
  ];

  // const handleChange = (event) => {
  //   // console.log(event.target.value);
  //   setBaseCurrency(event.target.value);
  // };

  return (
    <div className="ratesMainContainer">
      <div>
        <h1 className="headingContainers">Metals</h1>
      </div>
      <div className="outerContainer">
        <div className="containerHeaderMetal">
          <div></div>
          <div>{data.length > 0 && <h3>{formattedDateTime}</h3>}</div>
        </div>
        <div className="mainContainerBoxMetal">
          {data.length > 0 ? (
            <TableComponentId
              data={data}
              columns={columns}
              baseCurrency={baseCurrency}
              pages={page}
              fixedPoints={decimalPoints}
            />
          ) : (
            <h2 align="center" style={{ color: "white", fontFamily: "Roboto" }}>
              Rates Currently not avalible.
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default MetalContainer;

import React from "react";
import noWifiConnection from "../assets/noWifiConnection.png";
import "./CheckCOnnection.css";

const CheckConnection = () => {
  // window.addEventListener("offline", function () {
  //   document.querySelector("#noInternet").classList.remove("hidden"),
  //     (document.querySelector("#toastNotif").innerHTML =
  //       "<span>No internet connection!</span>");
  // }),
  //   window.addEventListener("online", function () {
  //     document.querySelector("#noInternet").classList.add("hidden"),
  //       (document.querySelector("#toastNotif").innerHTML =
  //         "<span>Internet connection restored!</span>");
  //   });
  return (
    <div id="noInternet" className="popSc">
      <div className="popBo">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <line x1="1" x2="23" y1="1" y2="23" />
          <path d="M16.72 11.06A10.94 10.94 0 0 1 19 12.55" />
          <path d="M5 12.55a10.94 10.94 0 0 1 5.17-2.39" />
          <path d="M10.71 5.05A16 16 0 0 1 22.58 9" />
          <path d="M1.42 9a15.91 15.91 0 0 1 4.7-2.88" />
          <path d="M8.53 16.11a6 6 0 0 1 6.95 0" />
          <line x1="12" x2="12.01" y1="20" y2="20" />
        </svg>
        <h2>Oops! No Internet!</h2>
        <p>
          Looks like you are facing a temporary network interruption.
          <br />
          Or check your network connection.
        </p>
      </div>
    </div>
  );
};

export default CheckConnection;

// import React from "react";
// import { Detector } from "react-detect-offline";
// import { FiWifiOff } from "react-icons/fi";
// import goldLogo from "../assets/goldLogo.png";
// const CheckConnection = (props) => {
//   return (
//     <div>
//       <Detector
//         render={({ online }) =>
//           online ? (
//             props.childern
//           ) : (
//             <div style={{ paddingTop: "10px", textAlign: "center" }}>
//               <img src={goldLogo} alt="" />
//               <h1 style={{ marginBottom: "5px" }}>No Connectiodsdsn</h1>
//               <h4 style={{ margin: "0" }}>
//                 Please Check Your Internet Connection
//               </h4>
//             </div>
//           )
//         }
//       />
//     </div>
//   );
// };

// export default CheckConnection;

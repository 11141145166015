import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

function generateDayWiseTimeSeries(baseval) {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  var date = new Date(baseval);
  var formattedDate = `${date.getDate()} ${
    months[date.getMonth()]
  } ${date.getFullYear()}`;
  return formattedDate;
}

const transformData = (data, metal, currency, PKR, weight) => {
  const seriesData = [];
  data.rates.forEach((entry) => {
    let rate;
    if (weight === "Ounce") {
      rate = 1 / entry[metal];
    } else if (weight === "Tola") {
      rate = 1 / entry[metal] / 2.66632373;
    } else if (weight === "Gram") {
      rate = 1 / entry[metal] / 31.09999998672;
    }
    seriesData.push({
      x: generateDayWiseTimeSeries(new Date(entry.key)),
      y: PKR && currency === "PKR" ? PKR * rate : rate,
      // y:
      //   PKR && currency === "PKR" ? PKR * (1 / entry[metal]) : 1 / entry[metal],
    });
  });

  return [
    {
      data: seriesData,
    },
  ];
};

const ApexCharts = ({
  date,
  currencyDropDown,
  metalDropDown,
  historyDataRates,
  PKR,
  weight,
}) => {
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        type: "line",
        height: 560,
        dropShadow: {
          enabled: false,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 3,
          color: "#000",
          opacity: 0.35,
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          // tools: {
          //   download: true,
          //   selection: true,
          //   zoom: true,
          //   zoomin: true,
          //   zoomout: true,
          //   pan: true,
          //   reset: true | '<img src="/static/icons/reset.png" width="20">',
          //   customIcons: [],
          // },
          export: {
            csv: {
              headerValue: "",
            },
          },
          autoSelected: "zoom",
        },

        fontFamily: "Roboto",
      },

      colors: ["#3C6E71"],
      xaxis: {
        type: "datetime",
        labels: {
          rotate: 0,
          rotateAlways: true,
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top",
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#333"],
          },
          offsetX: 30,
        },
      },
      yaxis: {
        show: true,
        showAlways: true,
        showForNullSeries: true,
        seriesName: undefined,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: [],
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: 400,
            cssClass: "apexcharts-yaxis-label",
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: function (value) {
            return `${value.toFixed(2)}`;
          },
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },

        crosshairs: {
          show: true,
          position: "back",
          stroke: {
            color: "#b6b6b6",
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          offsetX: 0,
        },
      },

      //For line styles
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: ["rgb(225,157,23)"],
        width: 2,
        dashArray: 0,
      },

      tooltip: {
        enabled: true,
        followCursor: true,
        inverseOrder: true,
        custom: undefined,
        fillSeriesColor: false,
        style: {
          fontSize: "12px",
          fontFamily: undefined,
          margin: "0 auto",
        },
        onDatasetHover: {
          highlightDataSeries: false,
        },
        x: {
          show: true,
          format: "dd MMMM yyyy",
          formatter: undefined,
        },
      },
    },
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      options: {
        ...prevState.options, // Preserve other properties in options
        chart: {
          ...prevState.options.chart,
          id: undefined,
          toolbar: {
            ...prevState.options.chart.toolbar,
            export: {
              svg: {
                filename: `${
                  metalDropDown === "XAU" ? "Gold" : "Silver"
                } Rates (Per ${weight})${date}`,
              },
              png: {
                filename: `${
                  metalDropDown === "XAU" ? "Gold" : "Silver"
                } Rates (Per ${weight})${date}`,
              },
              csv: {
                ...prevState.options.chart.toolbar.export.csv,
                filename: `${
                  metalDropDown === "XAU" ? "Gold" : "Silver"
                } Rates (Per ${weight})${date}`,
                columnDelimiter: ",",
                headerCategory: ["Date", `Metal Rates in ${currencyDropDown}`],
              },
            },
          },
        },
        subtitle: {
          text: `${
            metalDropDown === "XAU" ? "Gold" : "Silver"
          } Rates (Per ${weight})${date}`,
          align: "center",
          margin: 80,
          offsetX: 0,
          offsetY: 0,
          floating: true,
          style: {
            fontSize: "1.5vmax",
            fontWeight: "normal",
            fontFamily: "Roboto",
            color: "#3C6E71",
          },
        },
        yaxis: {
          ...prevState.options.yaxis,
          title: {
            text: `Rate (in ${currencyDropDown})`,
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "18px",
              fontFamily: "Roboto",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        tooltip: {
          ...prevState.options.tooltip,
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) =>
                `${metalDropDown}(${currencyDropDown}):   ${
                  currencyDropDown === "USD" ? "$" : "Rs."
                }`,
            },
          },
        },
      },

      series: transformData(
        historyDataRates,
        metalDropDown,
        currencyDropDown,
        PKR,
        weight
      ),
    }));
  }, [currencyDropDown, metalDropDown, historyDataRates, PKR, weight]);

  return (
    <div id="wrapper">
      <div id="chart-line">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="line"
          height={400}
          style={{
            width: "95vw",
            height: "50vh",
            margin: "0 auto",
            zIndex: "-10 !important",
          }} // Set the width here
        />
      </div>
    </div>
  );
};

export default ApexCharts;

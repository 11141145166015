import React, { Fragment, useEffect } from "react";

// import Advertisment from "../CustomVideoAdd/Advertisment.jsx";
// import advertisment1 from "../../assets/Advertisment1.mp4";
// import advertisment2 from "../../assets/Advertisment2.mp4";
// import add1 from "../../assets/add1.png";
// import add2 from "../../assets/add2.png";

// for Model
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import "./Notice.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "85%",
  bgcolor: "background.paper",
  border: "2px solid #c3a350",
  boxShadow:
    " rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
  p: 4,

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
const Notice = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setOpen(true);
    // // Set a timeout to automatically close the modal after 10 seconds
    // const timeoutId = setTimeout(() => {
    //   handleClose();
    // }, 5000);

    // Clear the timeout when the component is unmounted or the modal is closed manually
    // return () => clearTimeout(timeoutId);
  }, []);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        className="responsiveNotice"
        sx={{}}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{
                color: "white",
                background: "#3c6e71",
                width: "90%",
                borderRadius: "15px",
                padding: "10px",
                textAlign: "center",
                fontFamily: "Roboto",
              }}
              className="responsiveHeading"
            >
              Attention Please
            </Typography>
            <Typography
              id="transition-modal-description"
              sx={{
                mt: 2,
                width: "90%",
                fontFamily: "Roboto",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              className="responsiveParagraph"
              align="justify"
            >
              We neither involved in gold selling, buying or trading, nor or
              affiliated with any gold dealer / jewellers. So we do not provide
              gold trading selling, buying or jewellery manufacturing facility.
              Local rates shown here are just for your information and we are
              not liable for any kind of claims on these rates. We do not
              recommend any jewellers or trader for gold purchase. Therefore, we
              are not responsible or liable whatsoever with respect to any
              transactions made on the basis of given gold rates.
            </Typography>
            <Typography
              id="transition-modal-description"
              sx={{
                mt: 2,
                width: "90%",
                fontFamily: "Roboto",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              className="responsiveParagraph"
              align="center"
            >
              These Rates are only for local gold market of Rawalpindi/Islamabad.
            </Typography>
            {/* <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              +92 334 096 0444
            </Typography> */}
            <Typography
              variant="h6"
              component="h2"
              onClick={handleClose}
              sx={{
                marginTop: "20px",
                fontFamily: "Roboto",
                color: "white",
                background: "#3c6e71",
                width: "20%",
                borderRadius: "15px",
                padding: "7px",
                textAlign: "center",
                "&:hover": {
                  cursor: "pointer",
                  background: "#3c6171",
                },
              }}
              className="margintopresp"
            >
              Close
            </Typography>
            {/* <div className="contaneradv">
              <div className="advertisment">
                <img
                  src={add1}
                  alt="SoftWiseSolutions"
                  title="Soft Wise Solution"
                  className="imageStyleAdvertisment"
                />
                <img
                  src={add2}
                  alt="SoftWiseSolutions"
                  title="Soft Wise Solution"
                  className="imageStyleAdvertisment"
                />
                <Advertisment
                  src={advertisment1}
                  id="myVideo1"
                  muted={true}
                  autoplay={true}
                  ratio={"4_3"}
                  loop={true}
                />
              </div>
              <div className="advertisment">
                <Advertisment
                  src={advertisment2}
                  id="myVideo1"
                  muted={true}
                  autoplay={true}
                  ratio={"4_3"}
                  loop={true}
                />
              </div>
            </div> */}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default Notice;

import React, { Fragment, useState, useEffect } from "react";
import "./MetalsConvertor.css";
import KaratGoldCard from "./KaratGoldCard";
const MetalsConvertor = ({
  heading,
  metal,
  heading2,
  rawaSale,
  rawaPurchase,
  pageName,
}) => {
  const [value, setValue] = useState(1);
  const [metalValue, setMetalValue] = useState(1);
  // console.log("====================================");
  // console.log(metal, " ", metalValue);
  // console.log(rawaPurchase);
  // console.log(typeof rawaPurchase);
  // console.log("====================================");
  useEffect(() => {
    if (pageName === "Gram") {
      setMetalValue(rawaSale / 11.664);
    } else if (pageName === "Tola") {
      setMetalValue(rawaSale);
    } else if (pageName === "Ounce") {
      setMetalValue(rawaSale * 2.66632373);
    }
  }, []);

  const handleOfferChange = (e) => {
    if (e.target.value === 1 || e.target.value === "1") {
      // console.log("rawa Sale", rawaSale);
      if (pageName === "Gram") {
        setMetalValue(rawaSale / 11.664);
      } else if (pageName === "Tola") {
        setMetalValue(rawaSale);
      } else if (pageName === "Ounce") {
        setMetalValue(rawaSale * 2.66632373);
      }
    } else if (e.target.value === 2 || e.target.value === "2") {
      // console.log("rawa Purchase", rawaPurchase);
      if (pageName === "Gram") {
        setMetalValue(rawaPurchase / 11.664);
      } else if (pageName === "Tola") {
        setMetalValue(rawaPurchase);
      } else if (pageName === "Ounce") {
        setMetalValue(rawaPurchase * 2.66632373);
      }
    }
    // console.log(e.target.value);
  };
  return (
    <Fragment>
      <div className="calculatorContainerMain">
        <h1 className="calculatorMainHeader">{heading}</h1>
        <p className="calculatorsubHeading">
          This calculator is for estimation only and does not guarantee any
          value.
        </p>
        <h2 className="calculatorsubHeadingMetal">
          <div>
            <select
              className="dropdown height"
              name="offer"
              id="offer"
              onChange={(e) => {
                handleOfferChange(e);
              }}
            >
              <option value="1">Want to Sell</option>
              <option value="2">Want to Purchase</option>
            </select>
          </div>
          <input
            type="number"
            value={value}
            onChange={(e) => {
              if (/^\d+$/.test(e.target.value) || e.target.value === "") {
                setValue(e.target.value);
              }
            }}
          />{" "}
          {metal} Gold Rate
        </h2>
        <div className="gridContainer">
          <KaratGoldCard
            heading="24"
            value={(24 / 24) * metalValue * value}
            heading2={heading2}
          />
          <KaratGoldCard
            heading="22"
            value={(22 / 24) * metalValue * value}
            heading2={heading2}
          />
          <KaratGoldCard
            heading="21"
            value={(21 / 24) * metalValue * value}
            heading2={heading2}
          />
          <KaratGoldCard
            heading="18"
            value={(18 / 24) * metalValue * value}
            heading2={heading2}
          />
          <KaratGoldCard
            heading="16"
            value={(16 / 24) * metalValue * value}
            heading2={heading2}
          />
          <KaratGoldCard
            heading="12"
            value={(12 / 24) * metalValue * value}
            heading2={heading2}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default MetalsConvertor;

import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      {/* <div class="waves">
        <div class="wave" id="wave1"></div>
        <div class="wave" id="wave2"></div>
        <div class="wave" id="wave3"></div>
        <div class="wave" id="wave4"></div>
      </div>
      <ul class="social-icon">
        <li class="social-icon__item">
          <a class="social-icon__link" href="#">
            <ion-icon name="logo-facebook"></ion-icon>
          </a>
        </li>
        <li class="social-icon__item">
          <a class="social-icon__link" href="#">
            <ion-icon name="logo-twitter"></ion-icon>
          </a>
        </li>
        <li class="social-icon__item">
          <a class="social-icon__link" href="#">
            <ion-icon name="logo-linkedin"></ion-icon>
          </a>
        </li>
        <li class="social-icon__item">
          <a class="social-icon__link" href="#">
            <ion-icon name="logo-instagram"></ion-icon>
          </a>
        </li>
      </ul>
      <ul class="menu">
        <li class="menu__item">
          <a class="menu__link" href="/">
            Live Rates
          </a>
        </li>
        <li class="menu__item">
          <a class="menu__link" href="/goldHistory">
            Insights
          </a>
        </li>
        <li class="menu__item">
          <a class="menu__link" href="/calculateMetals">
            Gold Calculator
          </a>
        </li>
        <li class="menu__item">
          <a class="menu__link" href="/calculatesilver">
            Silver Calculator
          </a>
        </li>
        <li class="menu__item">
          <a class="menu__link" href="/trading">
            Trading Guide
          </a>
        </li>
      </ul> */}
      <p>&copy;2023 Soft Wise Solutions | All Rights Reserved</p>
      <p>
        Contact:{" "}
        <a href="https://wa.me/+923340960444" target="_blank">
          +92 334 096 0444
        </a>
      </p>
      {/* <p>Contact us : +92 334 096 0444</p> */}
    </footer>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";

import TableComponentId from "../tableComponent/tableComponentId";

//for importing css files
import "./RatesContainer.css";

let page = "Currency";
let decimalPoints = 2;
const RatesContainer = ({ data, update }) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    // Update the date and time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  // Format the date to "DD-MM-YYYY hh:mm:ss A"
  // Format the date and time
  const formattedDateTime =
    currentTime.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }) +
    " " +
    currentTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

  const columns = [
    { field: "displayName", label: "Name" },
    { field: "normal", label: "BID" },
    { field: "normal", label: "ASK" },
    {
      field: "data",
      label: "Low/High",
      format: "lowHigh",
      render: (item) => `${item.data.low} </br> ${item.data.high}`,
    },
  ];

  return (
    <div className="ratesMainContainer">
      <div>
        <h1 className="headingContainers">currency</h1>
      </div>
      <div className="outerContainer">
        <div className="containerHeader">
          <div></div>
          <div></div>
          <div>{data.length > 0 && <h3>{formattedDateTime}</h3>}</div>
        </div>
        <div className="mainContainerBox">
          {data.length > 0 ? (
            <TableComponentId
              data={data}
              columns={columns}
              // baseCurrency={baseCurrency}
              pages={page}
              fixedPoints={decimalPoints}
            />
          ) : (
            <h2 align="center" style={{ color: "white", fontFamily: "Roboto" }}>
              Rates Currently not avalible.
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default RatesContainer;
